import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			selectedImageIndex: undefined
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		//console.log("facility==>" + parseInt(this.$el.dataset.facilityid, 10));
		let el = document.querySelector("[data-facility-id]");
		var facilityId;
		if (el) {
			facilityId = parseInt(el.getAttribute('data-facility-id'), 10);
		} else {
			console.log('element is not found..................');
		}
		//console.log('facilityId::' + facilityId);

		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var unitgroupid;
		if (facilityId == 186031) { //Goldbachmühle
			unitgroupid = 44849;
		} else if (facilityId == 186040) { //LakeSpace 
			unitgroupid = 44866;
		} else if (facilityId == 188532) { // Landhaus zum See
			unitgroupid = 44867;
		} else if (facilityId == 188766) { //Parkvilla Schachen 7 
			unitgroupid = 44868;
		} else if (facilityId == 188533) { //Patrizierhaus Münsterstraße
			unitgroupid = 44869;
		} else if (facilityId == 186019) { //Residenz am See 
			unitgroupid = 44865;
		}

		var req = {
			fields: this.resultFields,
			filter: {
				//unitgroupid: 25551
				//'region.id': 8155
				unitgroupid: unitgroupid
			},
			sorting: 'random',
			//pricelist: true,
			max: 6
		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;
			});

	},

	created() {
		window.addEventListener("resize", this.myEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.myEventHandler);
	},

	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
		$(".owl-carousel.region").owlCarousel({
			loop: false,
			rewind: true,
			margin: 30,
			smartSpeed: 1000,
			autoplay: false,
			nav: true,
			responsive: {
				0: {
					items: 1
				},
				768: {
					items: 2
				},
				996: {
					items: 3

				}
			}
		});
	},
	methods: {
		getUnitSearchTarget: function (id) {
			return '_self';
			/*	if (window.screen.width < 768) {
					//probably phone?
					return '_self';
				} else {
					return 'unit_' + id;
				} */
		},
		getUnitgroupId: function (id) {
			return 44849;
		},
		getfacilityId: function (id) {
			return parseInt(this.$el.dataset.facilityid, 10);
		},

		myEventHandler(e) {
			//	console.log(window.innerWidth);
		}
	}

};